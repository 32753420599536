import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { notify, useLoginStatus } from 'andoncloud-sdk';
import { AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';

import { CenteringContainer, Container } from '@/components/core';
import { getWorkplaceConfig, mapScreenToPath } from '@/helpers';
import { notifyServerError } from '@/helpers/errors';
import { isTransitionPermitted } from '@/helpers/isTransitionPermitted';
import { getReasonsTreeDepth, sortReasons } from '@/helpers/reasons';
import { buildReasonURL } from '@/helpers/urls/buildReasonURL';
import { buildScreenSaverURL } from '@/helpers/urls/buildScreenSaverURL';
import { useLastStatusChange, useMillerColumns, usePrevious } from '@/hooks';
import backIcon from '@/images/backIcon.svg';
import loader from '@/images/loader.svg';
import { ScreenEnum, useQuery, WorkplaceModelType } from '@/models';
import { ReasonModelType } from '@/models/ReasonModel';
import statusChangesMutations from '@/mutations/status-changes';
import { useCompanyData, useStatusChangeTransitionPermissions, useWorkplaceData } from '@/providers';
import type { ColumnProps, ColumnsStore, NullableID, ReasonsParamTypes } from '@/types';

import { LoadingColumn } from '../loaders/ColumnLoader';
import StatusScreen from '../StatusScreen';

import ReasonActionModal from './ReasonActionModal';
import ReasonsColumn from './ReasonsColumn';
import RecommendedReasonsList from './RecommendedReasonsList';
import ShowRecommendationsButton from './ShowRecommendationsButton';
import { BackButton, BackImg, ColumnHeaderPlaceholder, ColumnsContainer, MillerColumnsContainer } from './styled';

interface ReasonsProps {
  currentScreen?: string;
}

export const Reasons: React.FC<ReasonsProps> = observer(({ currentScreen }) => {
  const { authResponse } = useLoginStatus();
  const { reasonID, workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const [searchParams] = useSearchParams();
  const reasonHasBeenRecommended = searchParams.get('src') === 'ai';
  const { statusChangeTransitionPermissions } = useStatusChangeTransitionPermissions();
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const {
    data: workplaceData,
    loading: workplaceDataLoading,
    statusChangeTransitionPermissionsLoaded,
  } = useWorkplaceData();
  const { setQuery } = useQuery();
  const [currentWorkplace, setCurrentWorkplace] = useState<WorkplaceModelType | null>(
    () => (!companyDataLoading && companyData?.workplaces?.find((workplace) => workplace.id === workplaceID)) || null,
  );
  const reasonsRecommedationsEnabled =
    companyData?.companyConfig?.reasonsRecommendationsEnabled && currentWorkplace?.reasonsRecommendationsEnabled;
  const lastStatusChange = useLastStatusChange();
  const workplaceConfig = getWorkplaceConfig(companyData?.companyConfig, workplaceID);
  const screenSaverConfig = workplaceConfig?.screenSaverConfig;
  const redirectToScreenSaver = screenSaverConfig?.enabled && screenSaverConfig?.displayAfterStatusChangeCreated;
  const sortedReasons = useMemo<ReasonModelType[]>(
    () =>
      (!workplaceDataLoading &&
        workplaceData?.reasons
          ?.filter(
            (reason) => reason.reasonGroupId !== undefined && reason.reasonGroupId === currentWorkplace?.reasonGroupId,
          )
          .sort((first, second) => sortReasons(first, second))) ||
      [],
    [workplaceData, workplaceDataLoading, currentWorkplace?.reasonGroupId],
  );
  const [reasonsTreeDepth, setReasonsTreeDepth] = useState<number>(0);
  const showRecommendationsCookieValue = Cookies.get('show-reasons-recommendations');
  const [showRecommendations, setShowRecommendations] = useState<boolean>(
    showRecommendationsCookieValue?.length ? showRecommendationsCookieValue === 'true' : true,
  );
  const [selectedReason, setSelectedReason] = useState<ReasonModelType | undefined>(
    sortedReasons.find((reason) => reason.id === reasonID),
  );
  const prevSelectedReason = usePrevious(selectedReason);
  const columns: ColumnsStore = useMillerColumns();
  /* const prevColumnsState = usePrevious<Array<ColumnProps>>(columns.state); */
  const [columnsList, setColumnsList] = useState<React.ReactElement[]>([]);
  /* const startDummy = useRef<null | HTMLDivElement>(null); */
  const endDummy = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const intl = useIntl();

  const shouldRenderButton =
    reasonsRecommedationsEnabled && (reasonsTreeDepth === 1 || columns.length <= Math.min(reasonsTreeDepth - 1, 2));

  useEffect(() => {
    setCurrentWorkplace(
      (!companyDataLoading && companyData?.workplaces?.find((workplace) => workplace.id === workplaceID)) || null,
    );
  }, [companyDataLoading, companyData?.workplaces, workplaceID]);

  useEffect(() => {
    setReasonsTreeDepth(getReasonsTreeDepth(sortedReasons));
  }, [sortedReasons]);

  useEffect(() => {
    if (endDummy.current) endDummy.current.scrollIntoView({ behavior: 'smooth' });
  });
  // this useEffect reconstructs the visual path to a URL that a user has selected
  // it fills the columns store with appropriate column data (parentID, selectedID)
  useEffect(() => {
    if (!columns.state.length && sortedReasons.length) {
      if (reasonID) {
        const columnsData: Array<ColumnProps> = [];
        let currentId: NullableID = reasonID;
        if (sortedReasons.find((reason) => reason.parentId === reasonID))
          columnsData.push({ parentID: reasonID, selectedID: null });
        do {
          // eslint-disable-next-line no-loop-func
          const currentReason = sortedReasons.find((reason) => reason.id === currentId);
          if (currentReason) {
            currentId = currentReason?.parentId;
            const currentColumnData = { parentID: currentReason?.parentId, selectedID: currentReason?.id };
            columnsData.unshift({ ...currentColumnData });
          }
          // if reason from URL has not been found we redirect user to the workplace reasons
          if (currentId === reasonID && !currentReason) {
            navigate(buildReasonURL(workplaceID));
            break;
          }
        } while (currentId);
        columns.setState(columnsData);
      } else {
        columns.setState([{ parentID: null, selectedID: null }]);
      }
    }
    setSelectedReason(sortedReasons.find((reason) => reason.id === reasonID));
  }, [reasonID, sortedReasons, columns, columns.state, navigate, workplaceID]);

  const sendStatusChange = useCallback(
    (reason: ReasonModelType, note?: string) => {
      if (authResponse) {
        setQuery((rootStore) =>
          statusChangesMutations.create({
            rootStore,
            workplaceId: workplaceID,
            userData: authResponse.user,
            lastStatusChange,
            reason,
            reasonHasBeenRecommended,
            note,
            // eslint-disable-next-line consistent-return
            optimisticUpdate: () => {
              columns.clear();

              if (redirectToScreenSaver) {
                navigate(buildScreenSaverURL(workplaceID));
              } else {
                const defaultScreen = workplaceConfig?.defaultScreen || ScreenEnum.STATUS_SCREEN;

                navigate(mapScreenToPath(defaultScreen, workplaceID));
              }
            },
            onSuccess: () => {
              notify.success(
                intl.formatMessage({
                  defaultMessage: 'Status change created',
                  description: 'Reason action modal notify success message',
                }),
              );
            },
            onError: (error, isValidationError) => {
              if (isValidationError) {
                notify.error(error);
              } else {
                notifyServerError(error, intl);
              }
              navigate(buildReasonURL(workplaceID));
            },
          }),
        );
      }
    },
    [
      workplaceID,
      reasonHasBeenRecommended,
      workplaceConfig?.defaultScreen,
      authResponse,
      columns,
      lastStatusChange,
      redirectToScreenSaver,
      setQuery,
      navigate,
      intl,
    ],
  );

  useEffect(() => {
    if (
      prevSelectedReason?.id !== selectedReason?.id &&
      selectedReason?.leaf &&
      !selectedReason.acceptanceMonit &&
      !selectedReason.hasNote
    ) {
      sendStatusChange(selectedReason);
    }
  }, [prevSelectedReason, selectedReason, sendStatusChange]);

  const toggleShowRecommendations = () => {
    setShowRecommendations(!showRecommendations);

    Cookies.set('show-reasons-recommendations', String(!showRecommendations));
  };

  const renderColumn = useCallback(
    (column: ColumnProps, key?: string) => {
      const { parentID } = column;
      const columnID = parentID ? `column-${parentID}` : `column-root`;
      return (
        <ReasonsColumn
          isLast={column.parentID === columns.lastColumnParentId}
          key={key || `column-${columns.length}`}
          parentID={parentID}
          columnID={columnID}
          sortedReasons={sortedReasons}
          reasonsTreeDepth={reasonsTreeDepth}
          columns={columns}
        />
      );
    },
    [columns, sortedReasons, reasonsTreeDepth],
  );
  useEffect(() => {
    setColumnsList(columns.state.map((column, i) => renderColumn(column, `column-${i + 1}`)));
  }, [columns.state, sortedReasons, renderColumn]);

  const renderActionModal = () => {
    return (
      reasonID &&
      (selectedReason?.acceptanceMonit || selectedReason?.hasNote) &&
      isTransitionPermitted({ statusChangeTransitionPermissions, toReasonId: reasonID }) && (
        <ReasonActionModal
          reason={selectedReason}
          open={!!selectedReason.leaf}
          columns={columns}
          onConfirm={(note) => sendStatusChange(selectedReason, note)}
        />
      )
    );
  };

  if (currentScreen === ScreenEnum.STATUS_SCREEN) {
    return (
      <>
        <Container
          style={{ height: 'calc(100vh - 177px)', marginTop: '15px' }}
          title={intl.formatMessage({
            defaultMessage: 'Workplace status',
            description: 'Reasons container status screen header text',
          })}
          data-testid="reasons-container"
        >
          <StatusScreen />
        </Container>
        {renderActionModal()}
      </>
    );
  }
  if (!statusChangeTransitionPermissionsLoaded)
    return (
      <Container
        style={{ height: 'calc(100vh - 177px)', marginTop: '15px' }}
        title={intl.formatMessage({
          defaultMessage: 'Choose workplace status',
          description: 'Reasons container statuses header text',
        })}
        data-testid="reasons-container"
      >
        <CenteringContainer data-testid="permissions-loading">
          <img src={loader} width="500px" alt="" />
          <FormattedMessage
            defaultMessage="Loading permissions is in progress..."
            description="Status change transition permissions loading text"
          />
        </CenteringContainer>
      </Container>
    );
  if (workplaceDataLoading)
    return (
      <Container
        style={{ height: 'calc(100vh - 177px)', marginTop: '15px' }}
        title={intl.formatMessage({
          defaultMessage: 'Choose workplace status',
          description: 'Reasons container statuses header text',
        })}
        data-testid="reasons-container"
      >
        <ColumnHeaderPlaceholder />
        <ColumnsContainer>
          <LoadingColumn />
        </ColumnsContainer>
      </Container>
    );
  return (
    <>
      <Container
        style={{ height: 'calc(100vh - 177px)', marginTop: '15px' }}
        title={intl.formatMessage({
          defaultMessage: 'Choose workplace status',
          description: 'Reasons container statuses header text',
        })}
        scrollable={false}
        data-testid="reasons-container"
      >
        {reasonsRecommedationsEnabled && showRecommendations && (
          <RecommendedReasonsList workplaceId={workplaceID} toggleShowRecommendations={toggleShowRecommendations} />
        )}
        <MillerColumnsContainer fullHeight={!reasonsRecommedationsEnabled || !showRecommendations}>
          <ColumnHeaderPlaceholder />
          <ColumnsContainer>
            {/* animate presence allows columns to be animated on enter and exit */}
            <AnimatePresence initial={false}>{columnsList}</AnimatePresence>
            <div key="end-dummy" ref={endDummy} />
          </ColumnsContainer>
          <BackButton
            data-testid="back-button"
            onClick={() => {
              if (reasonID) columns.removeAndDeselect(reasonID);
              navigate(buildReasonURL(workplaceID, selectedReason?.parentId || undefined));
            }}
            disabled={columns.state.length <= 1}
          >
            <BackImg src={backIcon} />
          </BackButton>
          {shouldRenderButton && (
            <ShowRecommendationsButton
              showRecommendations={showRecommendations}
              toggleShowRecommendations={toggleShowRecommendations}
            />
          )}
        </MillerColumnsContainer>
      </Container>
      {/* opens a reason modal when selected reason is a leaf */}
      {renderActionModal()}
    </>
  );
});
