import greenArrow from '@/images/greenArrow.svg';
import greenGear from '@/images/greenGear.svg';
import redArrow from '@/images/redArrow.svg';
import redGear from '@/images/redGear.svg';
import yellowArrow from '@/images/yellowArrow.svg';
import yellowGear from '@/images/yellowGear.svg';
import { ReasonModelType } from '@/models';

export const sortReasons = (reason1: ReasonModelType, reason2: ReasonModelType): number => {
  if (reason1.position && reason2.position) return reason1.position - reason2.position;
  return 0;
};

export const getReasonsTreeDepth = (reasons: ReasonModelType[]) => {
  const elementMap = new Map(reasons.map((el) => [el.id, el]));
  const depthCache = new Map();

  const calculateDepth = (elementId: string): number => {
    if (!elementMap.has(elementId)) return 0;

    if (depthCache.has(elementId)) return depthCache.get(elementId);

    const element = elementMap.get(elementId);

    if (!element) return 0;

    const depth = element.parentId === null ? 1 : calculateDepth(element.parentId) + 1;

    depthCache.set(elementId, depth);

    return depth;
  };
  let treeDepth = 0;

  for (const element of reasons) {
    treeDepth = Math.max(treeDepth, calculateDepth(element.id));
  }
  return treeDepth;
};

export const getReasonIcon = (reason: ReasonModelType): string => {
  if (!reason.leaf) {
    if (reason.statusColor === 'red') return redArrow;
    if (reason.statusColor === 'yellow') return yellowArrow;
    return greenArrow;
  }
  if (reason.statusColor === 'red') return redGear;
  if (reason.statusColor === 'yellow') return yellowGear;
  return greenGear;
};
