import { forwardRef, useEffect, useRef, useState } from 'react';

import { DialogBackground as StyledDialogBackground } from './styled';

interface DialogBackgroundProps {
  children: React.ReactNode;
}

export const DialogBackground = forwardRef<HTMLDivElement, DialogBackgroundProps>(({ children }, forwardedRef) => {
  const [windowHeight, setWindowHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [alignToCenter, setAlignToCenter] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWindowHeight = () => {
      setWindowHeight(window.innerHeight);
    };
    const updateContentHeight = () => {
      if (contentRef.current) {
        setContentHeight(contentRef.current.offsetHeight);
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      updateContentHeight();
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    updateWindowHeight();
    updateContentHeight();

    window.addEventListener('resize', updateWindowHeight);

    return () => {
      resizeObserver.disconnect();

      window.removeEventListener('resize', updateWindowHeight);
    };
  }, []);

  useEffect(() => {
    setAlignToCenter(contentHeight <= windowHeight);
  }, [windowHeight, contentHeight]);

  return (
    <StyledDialogBackground ref={forwardedRef} alignToCenter={alignToCenter}>
      <div ref={contentRef}>{children}</div>
    </StyledDialogBackground>
  );
});

export default DialogBackground;
