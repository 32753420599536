import { motion } from 'framer-motion';
import styled from 'styled-components';

interface ColumnProps {
  width?: string;
}

export const Column = styled(motion.div).attrs(() => ({
  variants: {
    hidden: {
      x: -300,
      opacity: 0,
      zIndex: 0,
    },
    visible: {
      zIndex: 30,
      x: 0,
      opacity: 1,
      transition: {
        zIndex: {
          delay: 0.5,
        },
      },
    },
  },
}))<ColumnProps>`
  position: relative;
  width: ${({ width }) => width || '33.33%'};
  scroll-snap-align: start;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  background-color: ${({ theme }) => theme.colors.mineSchaftDark};
  overflow-y: auto;
`;

export const ColumnHeader = styled.header`
  position: sticky;
  top: 0;
  padding: 4px 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  border-top: 1px solid #717171;
  border-bottom: 1px solid #717171;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.scorpion};
`;

export const ColumnBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
