import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { reaction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useStore } from '@/hooks';
import { useCurrentWorkplaceCounterDirectories } from '@/hooks/useCurrentWorkplaceCounterDirectories';
import { useCurrentWorkplaceCounters } from '@/hooks/useCurrentWorkplaceCounters';
import downArrow from '@/images/downArrow.svg';
import upArrow from '@/images/upArrow.svg';
import { CounterDirectoryModelType, CounterModelType } from '@/models';
import { theme } from '@/theme';
import { CounterModalCallback, ReasonsParamTypes } from '@/types';

import { CenteringContainer, Container } from '../core';
import ListLoader from '../loaders/ListLoader';

import Counter from './Counter';
import { AccordionItemButtonStyles, CountersValueCount } from './styled';

interface CountersProps {
  selectedCounter: CounterModelType | undefined;
  setSelectedCounter: Dispatch<SetStateAction<CounterModelType | undefined>>;
  setCounterModalCallback: Dispatch<SetStateAction<CounterModalCallback | null>>;
}

type CountersUnion = CounterDirectoryModelType | CounterModelType;

interface CountersTree {
  item: CountersUnion;
  children?: CountersTree[];
}

// O(n^2) complexity - not a good idea for a big set of counters to be displayed
const recursiveNest = (dataCopy: CountersUnion[], id: string | null = null): CountersTree[] => {
  return dataCopy
    .filter((item) => item.directoryId === id)
    .map((item) => ({ item, children: recursiveNest(dataCopy, item.id) }));
};

const recursiveCountChildrenSum = ({ tree }: { tree: CountersTree }): number => {
  let count = 0;
  if ('value' in tree.item && tree.item.value !== undefined) {
    count += tree.item.value;
  }
  if (tree.children?.length) {
    tree.children.forEach((child) => {
      count += recursiveCountChildrenSum({ tree: child });
    });
  }
  return count;
};

const Counters: React.FC<CountersProps> = observer(
  ({ selectedCounter, setSelectedCounter, setCounterModalCallback }) => {
    const { workplaceID } = useParams<keyof ReasonsParamTypes>();
    const store = useStore();
    const { counterDirectories, loading: loadingDirectories } = useCurrentWorkplaceCounterDirectories();
    const { counters, loading: loadingCounters } = useCurrentWorkplaceCounters();
    const [sortedCountersUnion, setSortedCountersUnion] = useState<CountersUnion[]>([]);
    const [sortedCounters, setSortedCounters] = useState<CounterModelType[]>();
    const [sortedCounterDirectories, setSortedCounterDirectories] = useState<CounterDirectoryModelType[]>();
    const [componentUpdateCount, setComponentUpdateCount] = useState<number>(0);
    const [countersEmpty, setCountersEmpty] = useState(
      counters && counters.length === 0 && counterDirectories && counterDirectories.length === 0,
    );
    const intl = useIntl();
    const isAnyCounterSelected = !!selectedCounter;
    const loading = loadingCounters && loadingDirectories;

    useEffect(() => {
      const countersReactionDisposer = reaction(
        () => toJS(store.counters),
        () => {
          setComponentUpdateCount((c) => c + 1);
        },
      );
      return () => {
        countersReactionDisposer();
      };
    });

    useEffect(() => {
      setSortedCounters(
        counters?.sort((counter1, counter2) => {
          if (counter1.kind === 'good' && counter2.kind === 'bad') {
            return -1;
          }
          if (counter2.kind === counter1.kind) {
            // @ts-expect-error undefined counter position (will always be defined)
            return counter1.position - counter2.position;
          }
          return 1;
        }),
      );
    }, [counters]);

    useEffect(() => {
      setSortedCounterDirectories(
        counterDirectories?.sort((counter1, counter2) => {
          // @ts-expect-error undefined counter position (will always be defined)
          return counter1.position - counter2.position;
        }),
      );
    }, [counterDirectories]);

    useEffect(() => {
      if (!loading && sortedCounters && sortedCounterDirectories)
        setSortedCountersUnion([...sortedCounterDirectories, ...sortedCounters]);
      setCountersEmpty(counters && counters.length === 0 && counterDirectories && counterDirectories.length === 0);
    }, [sortedCounters, sortedCounterDirectories, loading, counters, counterDirectories, workplaceID]);

    const nestedCountersTrees = useMemo(() => {
      if (sortedCountersUnion) return recursiveNest(sortedCountersUnion);
      return [];
    }, [sortedCountersUnion]);

    const renderCountersTrees = ({ items }: { items: CountersTree[] }): ReactElement[] => {
      return items.map((countersTree) => {
        // eslint-disable-next-line no-underscore-dangle
        if (countersTree?.children?.length === 0 && countersTree.item.__typename !== 'CounterDirectory') {
          const counter = countersTree.item;
          return (
            <Counter
              selectedCounter={selectedCounter}
              blurred={!(counter.id === selectedCounter?.id) && isAnyCounterSelected}
              selected={counter.id === selectedCounter?.id}
              key={`${counter.id}${componentUpdateCount}`}
              counter={counter}
              setSelectedCounter={setSelectedCounter}
              setCounterModalCallback={setCounterModalCallback}
              disabled={counter.isDisabled}
            />
          );
        }
        if (countersTree?.children?.length) {
          return (
            <Accordion style={{ background: theme.colors.youtubeBlack }} allowZeroExpanded key={countersTree.item.id}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton
                    data-testid={`counter-folder-${countersTree.item.id}`}
                    style={AccordionItemButtonStyles}
                  >
                    <span>
                      <CountersValueCount>{recursiveCountChildrenSum({ tree: countersTree })}</CountersValueCount>
                      {countersTree.item.name}
                    </span>
                    <AccordionItemState>
                      {({ expanded }) => {
                        if (expanded) return <img width="24px" src={upArrow} alt="" />;
                        return <img width="24px" src={downArrow} alt="" />;
                      }}
                    </AccordionItemState>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel style={{ background: theme.colors.mineSchaftDark, margin: '0 0 2px 25px' }}>
                  {renderCountersTrees({ items: countersTree.children })}
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          );
        }
        return <div key="empty" />;
      });
    };

    if (loading)
      return (
        <Container
          headerStyles={{ paddingBottom: '20px' }}
          style={{ height: 'calc(100vh - 177px)', marginTop: '15px', color: 'black' }}
          title={intl.formatMessage({
            defaultMessage: 'Counters',
            description: 'Counters container title',
          })}
          data-testid="counters-container-loading"
        >
          <ListLoader n={5} />
        </Container>
      );
    return (
      <Container
        headerStyles={{ paddingBottom: '20px' }}
        style={{ height: 'calc(100vh - 177px)', marginTop: '15px', color: 'black' }}
        title={intl.formatMessage({
          defaultMessage: 'Counters',
          description: 'Counters container title',
        })}
        onClick={() => {
          setSelectedCounter(undefined);
        }}
        data-testid="counters-container"
      >
        {countersEmpty ? (
          <CenteringContainer data-testid="empty-counters-message">
            {intl.formatMessage({
              defaultMessage: 'No counters found for this workplace',
              description: 'Counters container empty message',
            })}
          </CenteringContainer>
        ) : (
          <div data-testid="counters-list-container">
            {nestedCountersTrees !== undefined && renderCountersTrees({ items: nestedCountersTrees })}
          </div>
        )}
      </Container>
    );
  },
);

export default Counters;
