import React from 'react';
import { createRoot } from 'react-dom/client';

import isPropValid from '@emotion/is-prop-valid';
import { registerServiceWorker } from 'andoncloud-sdk';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import { RootStore, StoreContext } from '@/models';
import { LastWorkplaceProvider, LocaleProvider } from '@/providers';

import DialogBackground from './components/core/Dialog/Background';
import gqlHttpClient from './utils/graphql-client';
import gqlWsClient from './utils/ws-client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStyle, gridTheme, theme } from './theme';

const rootStore = RootStore.create(undefined, { gqlHttpClient, gqlWsClient });

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

createRoot(rootElement).render(
  <React.StrictMode>
    <StoreContext.Provider value={rootStore}>
      <ThemeProvider theme={theme}>
        <StyleSheetManager shouldForwardProp={isPropValid}>
          <GlobalStyle />
          <GridThemeProvider gridTheme={gridTheme}>
            <LocaleProvider>
              <ModalProvider backgroundComponent={DialogBackground}>
                <LastWorkplaceProvider>
                  <App />
                </LastWorkplaceProvider>
              </ModalProvider>
            </LocaleProvider>
          </GridThemeProvider>
        </StyleSheetManager>
      </ThemeProvider>
    </StoreContext.Provider>
  </React.StrictMode>,
);

registerServiceWorker({
  publicUrl: process.env.PUBLIC_URL,
  mode: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  autoUpdate: true,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
