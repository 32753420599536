import { useEffect, useRef } from 'react';

import { App as AndonCloudApp, useLoginStatus } from 'andoncloud-sdk';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';

import wsClient from './utils/ws-client';
import { getRedirectPath } from './helpers';
import { usePrevious, useStore } from './hooks';
import { ApplicationInfoModelType, useQuery } from './models';
import { useLastWorkplace, useLocale } from './providers';
import { applicationInfoQS } from './queries';
import AppRouting from './routing';

const OAUTH2_BASE_URL = process.env.REACT_APP_OAUTH2_BASE_URL as string;

const GTM_ID = process.env.REACT_APP_GTM_ID as string;

const App: React.FC = observer(() => {
  const { status: loginStatus, authResponse } = useLoginStatus();
  const prevLoginStatus = usePrevious(loginStatus);
  const { data: applicationInfoData, setQuery } = useQuery<{ applicationInfo: ApplicationInfoModelType }>();
  const rootStore = useStore();
  const { currentLocale } = useLocale();
  const { lastWorkplaceId } = useLastWorkplace();

  const isAuthenticated = loginStatus === 'connected';
  const redirectPath = useRef(getRedirectPath());

  useEffect(() => {
    if (isAuthenticated && authResponse?.accessToken) {
      wsClient.connect(authResponse.accessToken, currentLocale);
    }
  }, [isAuthenticated, authResponse?.accessToken, currentLocale]);

  useEffect(() => {
    if (isAuthenticated && authResponse?.accessToken) {
      setQuery((store) => store.queryApplicationInfo({}, applicationInfoQS.ALL));
    }
  }, [isAuthenticated, authResponse?.accessToken, setQuery]);

  useEffect(() => {
    if (prevLoginStatus === 'connected' && loginStatus === 'not_authorized') {
      rootStore.reset();

      Cookies.remove('show-reasons-recommendations');
    }
  }, [rootStore, prevLoginStatus, loginStatus]);

  return (
    <AndonCloudApp
      baseUrl={OAUTH2_BASE_URL}
      authProps={{
        redirectPath: redirectPath.current,
        clientId: 'sample-app-client-id',
        grantType: 'password',
      }}
      gtmProps={{
        gtmId: GTM_ID,
      }}
      sentryProps={{
        enabled: process.env.NODE_ENV !== 'development',
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: `platformv2@${process.env.REACT_APP_VERSION}`,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        initialScope: {
          tags: { serverName: process.env.REACT_APP_SENTRY_SERVER_NAME },
        },
      }}
      metaProps={{
        appVersion: process.env.REACT_APP_VERSION,
        apiVersion: applicationInfoData?.applicationInfo.apiVersion,
        releaseVersion: '2024.08',
        releasePageLink: 'https://docs.andoncloud.com/releases/2024-08/',
      }}
    >
      <AppRouting isAuthenticated={isAuthenticated} lastWorkplaceId={lastWorkplaceId} />
    </AndonCloudApp>
  );
});

export default App;
